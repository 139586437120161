<template>
  <div class="feedback-wrap">
    <div class="feedback-item gift" @click="goInvite">
      <lottie-player class="gift-animation" src="https://res.yimiaopan.com/pan/lottie/gift.json" background="transparent"
        loop autoplay>
      </lottie-player>
      <div class="hover-tip">
        <p>邀用户，领现金</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goInvite() {
      if (!this.userinfo || !this.userinfo.unionid) {
        this.$toast.fail("请先登录");
        return this.showLoginDialog();
      }
      this.$router.push("/main/invite");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.feedback-wrap {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;

  .feedback-item {
    width: 50px;
    height: 50px;
    display: flex;
    margin-top: 15px;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $theme-color;
    box-shadow: 3px 3px 14px 0 rgba(0, 0, 0, 0.2),
      -3px -3px 14px 0 rgba(0, 0, 0, 0.2);

    &.gift {
      background-color: transparent;

      .gift-animation {
        margin-top: -5px;
      }
    }

    .img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      .hover-tip {
        visibility: visible;
        opacity: 1;
        bottom: 0px;
      }
    }

    .hover-tip {
      width: 180px;
      padding: 10px;
      display: flex;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      align-items: center;
      position: absolute;
      text-align: center;
      border-radius: 4px;
      right: 60px;
      bottom: -60px;
      border: 1px solid #eee;
      background-color: #fff;
      transition: all ease 0.2s 0.1s;
      box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.05),
        -2px -2px 14px 0 rgba(0, 0, 0, 0.05);

      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: -12px;
        bottom: 10px;
        z-index: 1;
        border-top: 10px solid transparent;
        border-left: 12px solid #eee;
        border-bottom: 10px solid transparent;
      }

      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: -10px;
        bottom: 10px;
        z-index: 2;
        border-top: 10px solid transparent;
        border-left: 12px solid #fff;
        border-bottom: 10px solid transparent;
      }

      .text {
        font-weight: bold;
        color: #333;
      }

      img {
        width: 160px;
      }
    }
  }
}

@media (max-width: 700px) {}</style>
